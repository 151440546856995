@media print {
  #page-header,
  #page-footer,
  .breadcrumb,
  .admin-menu-wrapper,
  #sidebar {
    display: none !important;
  }
  #page-content {
    width: 100% !important;
  }
  body {
    background: $white !important;
  }
  #page-container {
    &:before,
    &:after {
      content: none !important;
    }
  }
}