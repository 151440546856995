%list-caret {
  padding: 0;
  list-style-type: none;
  overflow: hidden;

  li {
    @include triangle();
  }
}

%list-dashed {
  padding: 0;
  list-style-type: none;
  overflow: hidden;

  li {
    position: relative;
    padding-left: $li-angle-width;
    &:before {
      content: "- ";
      width: $li-angle-width;
      position: absolute;
      margin-left: -$li-angle-width;
    }
  }
}

#content {
  ul {
    &.summaryLinks,
    &.list-caret,
    &.list-caret ul {
      @extend %list-caret;
    }
  }
  .summaryLinks {
    color: $blue-700;
  }
  ul.list-dashed {
    @extend %list-dashed;
  }
}