[class^="content-block"] {
  h3 {
    font-weight: $font-weight-semibold;
    margin-bottom: 1em !important;
  }
  p {
    margin-bottom: 1em;
    span,
    .label {
      font-weight: bold;
    }
  }
  .dfwp-list {
    list-style-type: none;
    padding-left: 0;
  }
}
.block-title {
  border: 1px solid $gray-200;
  background: #eef3f5 linear-gradient(to bottom, #fdfeff, #eef3f5) no-repeat;
  padding: 0.5em;
  margin-top: 2em;
}