.navbar-expand-md .nav-main {
  @include media-breakpoint-up(md) {
    padding: 0 .5em !important;
    .nav-link {
      font-size: rem(14);
      padding: em(19.5, 14);
      padding-top: em(9.75, 14);
      padding-bottom: em(6.5, 14);
      font-weight: $font-weight-semibold;
      border-bottom: 5px solid $gray-100;
      &:hover,
      &:focus {
        border-color: $blue-400;
      }
    }
    .active .nav-link {
      border-color: $blue-700;
    }
  }
}