.publication-meta {
  .image {
    width: 190px;
    height: 248px;
    margin-right: 2rem;
    margin-bottom: 2rem;
    border: 1px solid $border-color;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background: url(../img/sprite.png) no-repeat;
      background-position: -352px 0px;
      display: inline-block;
      width: 74px;
      height: 74px;
      z-index: 10;
    }
  }
  .image + .textblock {
    @include media-breakpoint-up(md) {
      .acknowledgment {
        max-width: 420px;
      }
    }
  }
}