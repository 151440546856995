.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-size-base {
  font-size: $font-size-base !important;
}
.text-sm {
  font-size: 85%;
}