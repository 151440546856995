.hero {
  .card {
    background-color: $blue-600;
    color: $white;
    border: 0;
    @include media-breakpoint-up(lg) {
      box-shadow: inset 0 -1px 0 rgba($white,.75);
      background-color: rgba($blue-900,.2);
      border-radius: $border-radius;
    }
    h2 {
      font-weight: normal;
      color: $body-color;
    }
    .summaryLinks,
    .summaryLinks a {
      color: $white !important;
    }
    @include media-breakpoint-only(md) {
      ul {
        column-count: 2;
      }
    }
  }
}