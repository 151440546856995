#back2top {
    color: $white;
    background: rgba($primary, .9);
    width: 28px;
    height: 24px;
    border-radius: 5px 0 0 5px;
    overflow:hidden;
    position:fixed;
    right: 0;
    bottom: 3.5rem;
    z-index:999;
    font-size: .75em;
    line-height:1.1;
    text-align:center;
    text-decoration:none;
    transition: all 0.35s;
    display: block;

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    > * {
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        transition: all 0.35s;
    }

    .fas {
        font-size: .75rem;
    }
    &:hover {
        background:rgba($primary, 1);
        text-decoration:none;
    }
}
