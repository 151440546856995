p:last-child {
  margin-bottom: 0;
}

body:not(.home-page) {
  #page-container {
    // background-color: rgba(lime,.1);
    h1, .h1 {
      margin-bottom: 1.2em;
    }
    h2, .h2 {
      color: $primary;
      font-weight: $font-weight-bold;
      &:not(:first-child) {
        margin-top: 1em;
      }
      a {
        //color: $brand-blue-light-25;
      }
    }
    h3, .h3 {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
      font-weight: bold;
    }
    h4, .h4 {
      //color: $brand-blue-light-25;
      font-weight: $font-weight-base;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    blockquote {
      padding-left: 25px;
    }
  }
}
// Links
#content {
  a {
   &[target="_blank"],
   &[target="_new"] {
       &:not(.btn):not(.no-icon) {
           &:after{
               margin-left:$space-icon-link;
               @include faw-icon(\f35d);
               font-size: 80%;
               vertical-align: 1px;
           }
           @each $doc, $value in $icon-link {
              @include icon-link($doc, $value);
           }
       }
   }
  }
  a.link-arrow {
    @include triangle($blue-700, true);
  }
}

.media-body {
  h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
}