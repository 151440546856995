#page-container {
  .card-app {
    position: relative;
    padding: 1.5em 1.75em;
    .meta {
      position: absolute;
      top: 2em;
      right: 2em;
      width: 16px;
      height: 15px;
      background: url(../img/sprite.png) no-repeat;
      background-position: -192px -32px;

      &.locked {
        background-position: -224px -32px;
      }
    }

    h2 {
      padding-right: 2rem;
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: $primary;
      }
    }

    &.card-app-summary {
      padding: 1.75rem;

      h2 {
        margin-top: 0 !important;
      }
    }
  }
}