body {
  background: $white url("../img/backgrounds/background.png") left 30px repeat-x;

  #page-container {
    position: relative;
    background-color: $white;
    &:before,
    &:after {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 324px;
    }
    &:before {
      background: linear-gradient(180deg, $black 0%, transparent 100% );
      filter: blur(10px);
      opacity: .3;
      transition: opacity 0.3s;
    }
    .box::after {
      background: inherit;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .ms-asset-icon {
      margin: 0 5px;
    }
    #page-content h1 {
      margin-right: 3.5rem;
    }
  }
}