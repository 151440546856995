.navbar-brand {
  white-space: normal;
  line-height: 1;
  @include media-breakpoint-up(md) {
    img {
      height: 80px;
    }
  }
}
.navbar {
  margin-left: $grid-gutter-width * -.5;
  margin-right: $grid-gutter-width * -.5;
  @include media-breakpoint-up(md) {
    margin: 0;
  }
  #navbar-header {
    padding: $grid-gutter-width * .5;
    padding-top: 0;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
.navbar-light {
  .navbar-collapse {
    background-color: $blue-700;
    @include media-breakpoint-up(md) {
      background-color: $white;
      box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
      flex-basis: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    .navbar-nav {
      color: $white;
      padding: rem(13) 0 rem(13) rem(13);

      .nav-link {
        color: $white;
        font-size: rem(15);
        @include triangle(currentColor);
        padding: $nav-link-padding-y/2 $nav-link-padding-x;
        &:before {
          top: 6px;
          width: $nav-link-padding-x;
        }

        &:hover,
        &:focus {
          color: $white;
        }
      }

      .active .nav-link {
        color: $white;
        font-weight: bold;
      }
    }
  }
}