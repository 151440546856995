.btn {
  &.btn-primary:not(.emsch) {
    background: linear-gradient(to bottom, #e1eff4, #8f9ca1);
    box-shadow: 0 1px 3px rgba($black, .15), 0 1px 0 rgba($white, .6);
    border: 1px solid #8d9a9d;
    border-bottom-color: #728288;
    color: $body-color;
    font-weight: $font-weight-bold;
    padding: 0.25em 1.75em;
    text-shadow: 0 1px 0 rgba($white, .6);
    transition: $transition-base;
    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      color: $body-color;
      text-decoration: none;
      background: linear-gradient(to bottom, #e1eff4, rgba(#8f9ca1,.9));
    }
  }
}