.events-list {
  .event {
    &:hover,
    &:focus {
      h3 {
        text-decoration: none !important;
      }
    }
  }
  .date {
    color: $gray-500;
    flex: 0 0 120px;
    &.heading-block {
      span {
        display: block;
        color: $body-color;
        font-size: 1rem;
        &.day {
          font-size: rem(42);
          color: #00b0d4;
          line-height: 1;
        }
        &.month {
          margin-top: .5em;
        }
      }
    }
  }
}