.nav-aside,
.search-filters {
  background-color: #e7f3f5;
  padding: 1.5em;
  border: 1px solid $blue-400;
  margin-bottom: 2.5em;
  position: relative;
  @include media-breakpoint-up(md) {
    &:after {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      background-color: #e7f3f5;
      border-top: 1px solid $blue-400;
      border-right: 1px solid $blue-400;
      position: absolute;
      right: -8px;
      top: 1.75em;
      transform: rotate(45deg);
    }
  }
}
.nav-aside {
  .nav {
    .nav {
      display: none;
    }
    li {
      margin: .25em 0;
      @include triangle($blue-700);
      &.active {
        &:before {
          content: '\f0d7';
          left: 0;
        }
        > .nav-link {
          color: $body-color;
          text-decoration: none;
          &:hover {
            color: $body-color;
          }
        }
        .nav {
          display: flex;
          li {
            &:before {
              content: none;
            }
          }
        }
      }
      .nav-link {
        padding: 0;
        text-decoration: underline;
        color: darken($primary,2.5);
        &:hover {
          color: $blue-bright;
        }
      }
    }
  }
}
.aside-block {
  h2 {
    font-size: $font-size-base;
    color: $body-color !important;
  }
}