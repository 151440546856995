.search-filters {
  a {
    color: darken($primary,2.5);

    &.more_checkboxes {
      color: darken($primary,7.5);
      &:after {
        margin-left:$space-icon-link;
        @include faw-icon($fa-var-chevron-down);
        font-size: 70%;
        vertical-align: 1px;
      }
      &[aria-expanded="true"]:after {
        content: fa-content($fa-var-chevron-up);
        vertical-align: 2px;
      }
    }
  }
  .active a {
    font-weight: bold;
  }
  h2 {
    color: darken($primary,2.5) !important;
    font-size: $h3-font-size;
    margin-bottom: 1.75em;
  }
  h3 {
    font-size: rem(15);
    margin-bottom: .75em !important;
  }
}