.breadcrumb {
  padding-top: rem(4);
  padding-bottom: rem(16);
  a {
    color: $breadcrumb-divider-color;
  }
  .active {
    font-weight: $font-weight-bold;
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  @include faw-icon(\f0da);
  width: rem(18);
  font-size: 85%;
  text-align: center;
  position: relative;
  top: 5px;
  left: 0;
}