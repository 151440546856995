#page-container,
.cke_editable {
  .card,
  .card-background,
  .focuscontent,
  .newsarticle {
    border: 1px solid $gray-200;
    h2 {
      margin-bottom: 1em;
    }
  }
  .card,
  .focuscontent {
    background: #eef3f5 linear-gradient(to bottom, #fdfeff, #eef3f5) no-repeat;
    background-size: 100% 262px;
    padding: 1rem;
    h1, h2 {
      font-size: rem(18);
      color: $primary;
    }
    .list-caret {
      li:before {
        color: $blue-700;
      }
    }
    &.hasImage {
      overflow: hidden;
      .image {
        float: left;
        margin-right: 45px;
        margin-bottom: 1rem;
        img {
          width: 112px;
          height: 78px;
          border: 5px solid $white;
          box-shadow: 0 1px 5px rgba($black, .15);
        }
      }
    }
  }
  .card-background,
  .newsarticle {
    background: url(../img/backgrounds/card-bg.png) no-repeat 50% 100%;
    background-size: 100% 50px;
    padding: 1.5em 1.75em;
    padding-bottom: 4em;
    box-shadow: 0 1px 9px rgba($black, .06);
  }
}