.nav-secondary {
  background-color: $blue-800;
  @include media-breakpoint-up(md) {
    padding: 0;
    background-color: transparent;
    position: relative;
    z-index: 10;
    transform: translateY(1.5rem);
    .nav-item {
      margin-left: 1em;
    }
    .nav-link {
      font-size: rem(12);
      color: $body-color;
      padding: 0;
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus {
        border-color: currentColor;
      }
    }
  }
}