.notification {
  margin-bottom: 1em;
  border: 1px solid $alert-border;
  padding: .25em 2em;
  background-color: $alert-bg;
  position: relative;
  font-size: rem(13);
  &.info {
    padding-left: 4em;
    &:after {
      content: '';
      background: url('../img/sprite.png') no-repeat;
      background-position: -128px -32px;
      display: inline-block;
      width: 15px;
      height: 13px;
      position: absolute;
      left: 1.25em;
      top: 1em;
    }

    > ul > li {
      padding: .35em 0;
      position: relative;
      & + li {
        border-top: 1px solid $alert-separator;
      }
      .arrow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: block;
        text-decoration: none;
        &:before {
          content: '–';
          display: block;
          width: 15px;
          height: 15px;
          position: absolute;
          right: 0;
          top: .45em;
          text-align: center;
          color: $body-color;
          font-weight: bold;
          font-size: 14px;
          line-height: 1;
        }
      }

      .alert-content {
        padding-right: 34px;
        height: auto;
        transition: $transition-collapse;
        a {
          position: absolute;
          z-index: 4;
          color: darken($blue, 2);
          position: relative;
          z-index: 2;
        }
        ul {
          list-style-type: disc;
        }
      }

      &.alert-hidden {
        .arrow:before {
          content: '+';
        }

        .alert-content {
          height: 1.5em;
          overflow: hidden;
          > p:first-child {
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
