@mixin triangle($color: currentColor, $bigger: false) {
  position: relative;
  padding-left: $li-angle-width;
  &:before {
    @include faw-icon(\f0da);
    color: $color;
    width: $li-angle-width;
    position: absolute;
    margin-left: 0;
    line-height: inherit;
    font-size: 80%;
    top: 3px;
    left: 3px;
    @if $bigger {
      font-size: 100%;
      top: 0;
      left: 0;
    }
  }
}