// LEGACY from RIZIV-INAMI-Markup.css

/* Font Color Standard Colors */
.rizivinamistyle-ForeColor-1{-ms-color:"Dark Red"; color: darkred;}
.rizivinamistyle-ForeColor-2{-ms-color:"Red"; color: red;}
.rizivinamistyle-ForeColor-3{-ms-color:"Gold"; color: gold;}
.rizivinamistyle-ForeColor-4{-ms-color:"Yellow"; color: yellow;}
.rizivinamistyle-ForeColor-5{-ms-color:"Light Green"; color: lightgreen;}
.rizivinamistyle-ForeColor-6{-ms-color:"Dark Green"; color: darkgreen;}
.rizivinamistyle-ForeColor-7{-ms-color:"Sky Blue"; color: skyblue;}
.rizivinamistyle-ForeColor-8{-ms-color:"Blue"; color: blue;}
.rizivinamistyle-ForeColor-9{-ms-color:"Dark Blue"; color: darkblue;}
.rizivinamistyle-ForeColor-10{-ms-color:"Dark Purple"; color: purple;}

/* Font Color Theme Colors */
.rizivinamistyle-ThemeForeColor-1-0{color: rgb(255, 255, 255); -ms-name:"Light 1"; -ms-color:"White";}
.rizivinamistyle-ThemeForeColor-1-1{color: rgb(242, 242, 242); -ms-name:"Light 1 Lightest"; -ms-color:"White";}
.rizivinamistyle-ThemeForeColor-1-2{color: rgb(216, 216, 216); -ms-name:"Light 1 Lighter"; -ms-color:"White";}
.rizivinamistyle-ThemeForeColor-1-3{color: rgb(191, 191, 191); -ms-name:"Light 1 Medium"; -ms-color:"White";}
.rizivinamistyle-ThemeForeColor-1-4{color: rgb(165, 165, 165); -ms-name:"Light 1 Darker"; -ms-color:"White";}
.rizivinamistyle-ThemeForeColor-1-5{color: rgb(127, 127, 127); -ms-name:"Light 1 Darkest"; -ms-color:"White";}

.rizivinamistyle-ThemeForeColor-2-0{color: rgb(0, 0, 0); -ms-name:"Dark 1"; -ms-color:"Black";}
.rizivinamistyle-ThemeForeColor-2-1{color: rgb(127, 127, 127); -ms-name:"Dark 1 Lightest"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeForeColor-2-3{color: rgb(89, 89, 89); -ms-name:"Dark 1 Lighter"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeForeColor-2-4{color: rgb(63, 63, 63); -ms-name:"Dark 1 Medium"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeForeColor-2-5{color: rgb(38, 38, 38); -ms-name:"Dark 1 Darker"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeForeColor-2-6{color: rgb(12, 12, 12); -ms-name:"Dark 1 Darkest"; -ms-color:"Black";}

.rizivinamistyle-ThemeForeColor-3-0{color: #e7f3f5; -ms-name:"Light 1"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeForeColor-3-1{color: #dbeef0; -ms-name:"Light 1 Lightest"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeForeColor-3-3{color: #cfe8eb; -ms-name:"Light 1 Lighter"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeForeColor-3-4{color: #c3e3e6; -ms-name:"Light 1 Medium"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeForeColor-3-5{color: #abd8dc; -ms-name:"Light 1 Darker"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeForeColor-3-6{color: #9fd2d7; -ms-name:"Light 1 Darkest"; -ms-color:"Accent 1";}

.rizivinamistyle-ThemeForeColor-4-0{color: #02819b; -ms-name:"Dark 1"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeForeColor-4-1{color: #94CDD3; -ms-name:"Dark 1 Lightest"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeForeColor-4-3{color: #83B6BB; -ms-name:"Dark 1 Lighter"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeForeColor-4-4{color: #739F14; -ms-name:"Dark 1 Medium"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeForeColor-4-5{color: #62888C; -ms-name:"Dark 1 Darker"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeForeColor-4-6{color: #527175; -ms-name:"Dark 1 Darkest"; -ms-color:"Accent 2";}

/* Highlight Color Standard Colors*/
.rizivinamistyle-BackColor-1{-ms-color:"Dark Red"; background-color: darkred;}
.rizivinamistyle-BackColor-2{-ms-color:"Red"; background-color: red;}
.rizivinamistyle-BackColor-3{-ms-color:"Gold"; background-color: gold;}
.rizivinamistyle-BackColor-4{-ms-color:"Yellow"; background-color: yellow;}
.rizivinamistyle-BackColor-5{-ms-color:"Light Green"; background-color: lightgreen;}
.rizivinamistyle-BackColor-6{-ms-color:"Dark Green"; background-color: darkgreen;}
.rizivinamistyle-BackColor-7{-ms-color:"Sky Blue"; background-color: skyblue;}
.rizivinamistyle-BackColor-8{-ms-color:"Blue"; background-color: blue;}
.rizivinamistyle-BackColor-9{-ms-color:"Dark Blue"; background-color: darkblue;}
.rizivinamistyle-BackColor-10{-ms-color:"Dark Purple"; background-color: purple;}

/* Highlight Color Theme Colors */
.rizivinamistyle-ThemeBackColor-1-0{background-color: rgb(255, 255, 255); -ms-name:"Light 1"; -ms-color:"White";}
.rizivinamistyle-ThemeBackColor-1-1{background-color: rgb(242, 242, 242); -ms-name:"Light 1 Lightest"; -ms-color:"White";}
.rizivinamistyle-ThemeBackColor-1-2{background-color: rgb(216, 216, 216); -ms-name:"Light 1 Lighter"; -ms-color:"White";}
.rizivinamistyle-ThemeBackColor-1-3{background-color: rgb(191, 191, 191); -ms-name:"Light 1 Medium"; -ms-color:"White";}
.rizivinamistyle-ThemeBackColor-1-4{background-color: rgb(165, 165, 165); -ms-name:"Light 1 Darker"; -ms-color:"White";}
.rizivinamistyle-ThemeBackColor-1-5{background-color: rgb(127, 127, 127); -ms-name:"Light 1 Darkest"; -ms-color:"White";}

.rizivinamistyle-ThemeBackColor-2-0{background-color: rgb(0, 0, 0); -ms-name:"Dark 1"; -ms-color:"Black";}
.rizivinamistyle-ThemeBackColor-2-1{background-color: rgb(127, 127, 127); -ms-name:"Dark 1 Lightest"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeBackColor-2-3{background-color: rgb(89, 89, 89); -ms-name:"Dark 1 Lighter"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeBackColor-2-4{background-color: rgb(63, 63, 63); -ms-name:"Dark 1 Medium"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeBackColor-2-5{background-color: rgb(38, 38, 38); -ms-name:"Dark 1 Darker"; -ms-color:"Gray-80%";}
.rizivinamistyle-ThemeBackColor-2-6{background-color: rgb(12, 12, 12); -ms-name:"Dark 1 Darkest"; -ms-color:"Black";}

.rizivinamistyle-ThemeBackColor-3-0{background-color: #e7f3f5; -ms-name:"Light 1"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeBackColor-3-1{background-color: #dbeef0; -ms-name:"Light 1 Lightest"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeBackColor-3-3{background-color: #cfe8eb; -ms-name:"Light 1 Lighter"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeBackColor-3-4{background-color: #c3e3e6; -ms-name:"Light 1 Medium"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeBackColor-3-5{background-color: #abd8dc; -ms-name:"Light 1 Darker"; -ms-color:"Accent 1";}
.rizivinamistyle-ThemeBackColor-3-6{background-color: #9fd2d7; -ms-name:"Light 1 Darkest"; -ms-color:"Accent 1";}

.rizivinamistyle-ThemeBackColor-4-0{background-color: #02819b; -ms-name:"Dark 1"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeBackColor-4-1{background-color: #94CDD3; -ms-name:"Dark 1 Lightest"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeBackColor-4-3{background-color: #83B6BB; -ms-name:"Dark 1 Lighter"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeBackColor-4-4{background-color: #739F14; -ms-name:"Dark 1 Medium"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeBackColor-4-5{background-color: #62888C; -ms-name:"Dark 1 Darker"; -ms-color:"Accent 2";}
.rizivinamistyle-ThemeBackColor-4-6{background-color: #527175; -ms-name:"Dark 1 Darkest"; -ms-color:"Accent 2";}