.print-btn {
  display: block;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 3em;
  top: 2em;
  z-index: 99;
  background: url(../img/sprite.png) no-repeat;
  background-position: -288px 0px;
  background-color: transparent !important;
  &:hover {
    background-position: -320px -32px;
  }
}