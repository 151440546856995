.search-group {
  box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.1);
  background-color: $white;
  border-radius: $border-radius;
  .form-control, button {
    font-size: rem(12);
    background-color: transparent;
    padding: 0.5em 1em;
    border-color: $input-border-color;
  }
  .form-control {
    width: 15em !important;
    height: auto;
    &::placeholder {
      font-style: italic;
    }
  }
}