// Assets
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;

// Body font-size
$browser-context: 14;

// Icons link
$icon-link: (
  pdf: \f1c1,
  doc: \f1c2,
  docx: \f1c2,
  xls: \f1c3,
  pptx: \f1c4,
  zip: \f1c6,
  txt: \f15c,
  accdb: \f15b,
);
$space-icon-link: 5px;

$white:    #fff !default;
$gray-100: #f3f9f9;
$gray-200: #e0e8ea;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #999;
$gray-600: #666e72;
$gray-700: #495057 !default;
$gray-800: #333;
$gray-900: #2d3235;
$black:    #000 !default;

$blue:    #02819b;
$blue-200: #e7f3f5;
$blue-400: #c1dde2;
$blue-500: #9ad2dd;
$blue-600: #6EAEC0;
$blue-700: #45A1C3;
$blue-800: #3D96B7;
$blue-900: #1d6570;

$blue-bright: lighten(saturate(#02819b, 2.55), 10);

$primary:       $blue !default;
$secondary:     $gray-600 !default;

$body-color:                $gray-900 !default;
$border-color:                $gray-300 !default;
$light: $gray-200;

$alert-bg: #fff6d7;
$alert-border: #716c57;
$alert-separator: #d3cbb3;

$admin-menu-icon-fill: $white;
$admin-menu-icon-size: 16;
$admin-menu-font-color: $white;

$li-angle-width: 1rem;

// Spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25), // 4
      2: ($spacer * .5), // 8
      3: $spacer, // 16
      4: ($spacer * 1.5), // 24
      5: ($spacer * 2), // 32
      6: ($spacer * 2.5), // 40
      7: ($spacer * 3), // 48
      8: ($spacer * 3.5), // 56
      9: ($spacer * 4), // 64
      10: ($spacer * 4.5) // 72
    ),
    $spacers
);

// Fonts
$font-family-sans-serif: 'Open Sans', Roboto, "Helvetica Neue", Arial, sans-serif;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600;
$font-weight-bold:            700 !default;
$font-size-base:              1rem;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 1.714; // 24
$h2-font-size:                $font-size-base * 1.286; // 18
$h3-font-size:                $font-size-base * 1.143; // 16
//$h4-font-size:                $font-size-base * 1.25; // 20

$headings-font-weight: $font-weight-normal;

//$lead-color: $brand-blue-light-25;
//$lead-font-weight:            400;

// Links
$link-hover-color: $blue-bright;
$link-decoration:                         underline;
$link-hover-decoration:                   none;

// Border
$border-color: $border-color;
$border-radius:               .5rem !default;
$border-radius-lg:               .5rem !default;

$hr-border-color: $border-color;

// Dropdown
$dropdown-min-width: 5rem;
//$dropdown-link-color:               $body-color;
//$dropdown-link-hover-color:         darken($body-color, 5%);
//$dropdown-link-hover-bg:            lighten($brand-blue-light-50, 10%);
//$dropdown-link-active-color:		$body-color;
//$dropdown-link-active-bg:           transparent;

// Navbar
$navbar-brand-font-size: rem(18);
$navbar-brand-height: calc(#{$navbar-brand-font-size} * #{$line-height-base});
$navbar-light-color: $body-color;
$navbar-padding-x: 0;
$navbar-padding-y: 0;
$navbar-brand-padding-y: 0;

$navbar-light-color: $body-color;
$navbar-light-toggler-border-color: transparent;
$navbar-light-brand-color: $primary;

// Close
$close-font-size:                   $font-size-base * 1.5;
$close-font-weight:                 400;
$close-text-shadow:                 none;

// Forms
$input-color: $body-color;
$input-border-color: $gray-300;
//$input-focus-border-color: $gray-800;
//$input-focus-box-shadow: 0,0,0,.2rem rgba($secondary, .25);
//$input-placeholder-color: $brand-blue-light-25;

// Checkboxes
$custom-checkbox-indicator-border-radius: .25rem;

// Breadcrumbs
$breadcrumb-font-size:              .857em;

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              2em;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          #0d515b;
$breadcrumb-active-color:           #1f2930;
$breadcrumb-divider:                quote("\f0da") !default;

$breadcrumb-border-radius:          0;

// Popover
$popover-bg:                        $body-color;
$popover-max-width:                 276px !default;
$popover-body-color:                $white;
$popover-border-color:              $body-color;

// Tooltip
$tooltip-bg: $body-color;

// Pagination
$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;
$pagination-border-color: $primary;
$pagination-hover-border-color: $primary;


// Tables
//$table-head-bg:               $brand-blue-light-25;
$table-head-color:            $white;
$table-th-font-weight:        $font-weight-semibold;

// Cards
$card-border-radius:                0;

// Badges
$badge-font-weight: normal;