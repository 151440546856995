#topnav {
  font-family: Arial, Verdana, sans-serif;
  font-size: rem(11);
  .language-switcher {
    .nav-link {
      font-size: rem(12);
      border: 1px solid $gray-500;
      padding: .1em;
      width: 1.5em;
      color: $gray-700;
      background-color: $blue-500;
      line-height: 1;
      text-align: center;
    }
    .active .nav-link {
      color: $white;
      background-color: $primary;
      border-color: $gray-800;
    }
  }
  #head-fed {
    border-bottom: 3px solid $black;
  }
}