footer {
  background: $blue-500;
  color: $body-color;
  h2 {
    font-size: rem(22);
  }
  a, a:hover {
    color: $body-color;
    text-decoration: none;
  }
  .footer-menu {
    a {
      color: $gray-700;
    }
    .viewmore {
      padding-top: 1em;
      font-size: rem(13);
      a {
        color: darken($blue,9);
        @include triangle($blue-700, true);
      }
    }
  }
  #footer-legal {
    li:not(:last-child):after {
      content: '|';
      margin-left: .5em;
    }
  }
  #copyright,
  #footer-legal {
    font-size: rem(13);
  }
}