#page-content table {
  width: 100%;
}

table {
  caption {
    caption-side: top;
  }
}

.s4-wpTopTable {
  border: 0;
  td {
    border: 0;
  }
  .ms-WPHeader {
    border: 1px solid $gray-200;
    background: #eef3f5 linear-gradient(to bottom, #fdfeff, #eef3f5) no-repeat;
    td {
      padding: 4px 0;
      &:first-child {
        width: 1rem;
      }
    }
    h2 {
      font-size: 1rem;
      margin: 0;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1em 0;
    li {
      padding: 6px;
    }
  }
}

.table-bordered, .rizivinamistyle-Table-default {
  th, td {
    border: 1px solid;
    border-color: $black !important;
  }
  th {
    text-align: center;
  }
}

.table-mediumlist {
  text-align: left;
  color: $black;
  border: 1px solid $primary;
  th {
    background-color: $primary;
    color: $white;
  }
  th, td {
    border: 0;
  }
}
// LEGACY from RIZIV-INAMI-Markup.css
.rizivinamistyle-Table-clean {
  tr:not(:last-of-type) {
    border-bottom: 1px solid $black;
  }
}

.rizivinamistyle-Table-lightlist {
  border: 1px solid #e7f3f5;
  .rizivinamistyle-TableHeaderRow-lightlist {
    th {
      background-color: #e7f3f5;
    }
  }
}

.rizivinamistyle-Table-darklist {
  border: 1px solid #233950;
  .rizivinamistyle-TableHeaderRow-darklist {
    th {
      background-color: #233950;
      color: $white;
    }
  }
}

.rizivinamistyle-Table-mediumlist {
  border: 1px solid $primary;
  .rizivinamistyle-TableHeaderRow-mediumlist {
    th {
      background-color: $primary;
      color: $white;
    }
  }
}
.rizivinamistyle-Table-bandedlist {
  .rizivinamistyle-TableHeaderRow-bandedlist {
    th {
      background-color: $primary;
      color: $white;
    }
  }
  tr:nth-of-type(odd) {
    background-color: #e7f3f5;
  }
}