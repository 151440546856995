.glossary-page {
  #page-container {
    section {
      margin-top: 1.75em;
      .heading-block {
        font-size: rem(20);
      }
      .textblock {
        padding: 1.5em 0;

        h3 {
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
  #az-list {
    .list-inline-item {
      font-size: rem(18);
      font-weight: $font-weight-bold;
      margin: 0 .571rem;
    }
  }
}